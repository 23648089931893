import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakLoginOptions, KeycloakRegisterOptions } from 'keycloak-js';

@Injectable({ providedIn: 'root' })
export class KeycloakLinkGenerator {
  constructor(private keycloakService: KeycloakService) {}

  private get keycloak() {
    return this.keycloakService.getKeycloakInstance();
  }

  createLoginUrl(options: KeycloakLoginOptions & { fairId?: string }) {
    return `${this.keycloak.createLoginUrl(options)}&fairId=${options.fairId ?? 'cross-fair'}`;
  }

  createRegisterUrl(options: KeycloakRegisterOptions & { fairId?: string }) {
    return `${this.keycloak.createRegisterUrl(options)}&fairId=${options.fairId ?? 'cross-fair'}`;
  }
}
