import { DOCUMENT, Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router, UrlCreationOptions } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RedirectUrlGenerator {
  private router = inject(Router);
  private location = inject(Location);
  private document = inject(DOCUMENT);

  createRedirectUrl(commands: any[], navigationExtras?: UrlCreationOptions): string {
    return (
      this.document.location.origin +
      this.location.prepareExternalUrl(this.router.serializeUrl(this.router.createUrlTree(commands, navigationExtras)))
    );
  }
}
