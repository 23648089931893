import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { KeycloakLinkGenerator } from './generators/keycloak-link.generator';

@NgModule({
  imports: [CommonModule],
  providers: [KeycloakLinkGenerator],
})
export class Bau365UtilKeycloakModule {}
